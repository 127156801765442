var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CNavbar',{staticClass:"p3 shadow nav-mobile",attrs:{"sticky":true,"expandable":"md","type":"dark"}},[_c('CToggler',{attrs:{"in-navbar":"","id":"toogleButton"},on:{"click":_vm.hanedletoogleLeft}},[_c('font-awesome-icon',{attrs:{"icon":"bars","size":"lg"}})],1),_c('CNavbarBrand',{staticClass:"ml-auto mr-auto",attrs:{"href":"#"},on:{"click":function($event){return _vm.toOrderPage()}}},[_c('img',{staticStyle:{"height":"30px"},attrs:{"src":require("@/assets/images/d-dot-logo.svg"),"alt":""}})]),_c('CToggler',{attrs:{"in-navbar":"","id":"toogle-right"},on:{"click":_vm.hanedletoogleRight}},[_c('b-avatar',{staticClass:"user-icon"})],1),(_vm.$store.state.isShowCloseButtonChatOrder)?_c('div',{staticClass:"panel-close-profile-order pb-1 pt-2"},[_c('span',{staticClass:"cursor-pointer my-auto",on:{"click":function($event){return _vm.$store.commit('UPDATE_SHOW_CLOSE_BUTTON_CHATORDER', false)}}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'times']}}),_vm._v(" ปิด ")],1)]):_vm._e(),_c('CCollapse',{attrs:{"show":_vm.collapsedLeft,"navbar":""}},[_c('CNavbarNav',{staticClass:"ml-auto mb-2 my-md-2 d-md-none"},[(
          _vm.$route.path !== '/login' && _vm.$route.path !== '/AccessDenied'
          //  && isShowHeaderTab
        )?_c('div',{staticClass:"ml-md-2"},[_c('b-dropdown',{attrs:{"id":"dropdown-chat-mode","variant":"light","right":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-between align-items-center w-100"},[_c('div',[_vm._v(" "+_vm._s(_vm.selectedChatModeName.name)+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.selectedChatModeName.totalCount)+" ")])])]},proxy:true}],null,false,3168443935)},_vm._l((_vm.chatModeList),function(mode,bindex){return _c('b-dropdown-item',{key:bindex,class:{ active: `${_vm.chatModeId}` === `${mode.modeId}` },on:{"click":function($event){return _vm.onChatModeChange(mode)}}},[_c('div',[_vm._v(" "+_vm._s(mode.name)+" ")]),_c('div',{staticClass:"total"},[_vm._v(" "+_vm._s(mode.totalCount)+" ")])])}),1)],1):_vm._e(),(
          _vm.$route.path !== '/login' && _vm.$route.path !== '/AccessDenied'
          //  && isShowHeaderTab
        )?_c('div',{staticClass:"ml-md-2"},[_c('b-dropdown',{attrs:{"id":"dropdown-channel-chat","variant":"light","right":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center w-100"},[(_vm.selectedChannelName.id != 99)?_c('img',{staticClass:"mr-2 rounded",attrs:{"height":"16","src":_vm.selectedChannelName.imageUrl,"alt":_vm.selectedChannelName.name}}):_vm._e(),_c('div',[_vm._v(" "+_vm._s(_vm.selectedChannelName.name)+" ")])])]},proxy:true}],null,false,3942039277)},_vm._l((_vm.socialChannelList),function(channel,bindex){return _c('b-dropdown-item',{key:bindex,class:{ active: `${_vm.socialChannelId}` === `${channel.id}` },on:{"click":function($event){return _vm.onChannelChange(channel)}}},[(channel.id != 99)?_c('img',{staticClass:"mr-2 rounded",attrs:{"height":"16","src":channel.imageUrl,"alt":channel.name}}):_vm._e(),_c('div',[_vm._v(" "+_vm._s(channel.name)+" ")])])}),1)],1):_vm._e()])],1),_c('CCollapse',{attrs:{"show":_vm.collapsedRight,"navbar":""}},[_c('CNavbarNav',{staticClass:"ml-auto mb-2 my-md-2"},[(0)?_c('div',[(
            _vm.$route.path !== '/login' && _vm.$route.path !== '/AccessDenied'
            //  && isStore == 1
            //  && isShowHeaderTab
          )?_c('div',{staticClass:"ml-md-2"},[_c('b-button',{attrs:{"variant":"light"},on:{"click":function($event){return _vm.setShowModalExport(true)}}},[_c('font-awesome-icon',{staticClass:"mr-2 color-primary",attrs:{"icon":"download"}}),_vm._v("Export Sheet ")],1)],1):_vm._e(),(
            _vm.$route.path !== '/login' && _vm.$route.path !== '/AccessDenied'
            //  && isShowHeaderTab
          )?_c('div',{staticClass:"ml-md-2"},[_c('b-button',{attrs:{"variant":"light"},on:{"click":function($event){return _vm.setShowModalImport(true)}}},[_c('font-awesome-icon',{staticClass:"mr-2 color-primary",attrs:{"icon":"upload"}}),_vm._v("Import Sheet ")],1)],1):_vm._e()]):_vm._e(),(
          _vm.$route.path !== '/login' && _vm.$route.path !== '/AccessDenied'
          //  && isShowHeaderTab
        )?_c('div',{staticClass:"ml-md-2"},[_c('b-dropdown',{attrs:{"id":"dropdown-brand","text":_vm.selectedBrandName,"variant":"light","right":""}},_vm._l((_vm.brandLists),function(brand,bindex){return _c('b-dropdown-item',{key:bindex,class:{ active: _vm.brandID === `${brand.id}` },on:{"click":function($event){return _vm.onBrandChange(brand)}}},[_c('div',[_vm._v(" "+_vm._s(brand.name)+" ")])])}),1)],1):_vm._e(),(_vm.$route.path !== '/login' && _vm.$route.path !== '/AccessDenied')?_c('div',{staticClass:"ml-md-2"},[_c('b-dropdown',{attrs:{"id":"dropdown-logout","variant":"light","right":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('b-avatar',{staticClass:"user-icon px-2 py-1"}),_c('span',{staticClass:"mx-2"},[_vm._v(" "+_vm._s(_vm.username)+" ")])]},proxy:true}],null,false,2539144639)},[_c('b-dropdown-item',{on:{"click":_vm.handleLogout}},[_c('font-awesome-icon',{staticClass:"mr-2 color-primary",attrs:{"icon":"sign-out-alt"}}),_vm._v("Log out ")],1)],1)],1):_vm._e()])],1),(_vm.forceRefresh)?[_c('div',[_c('SelectBrand',{attrs:{"isShow":_vm.isShowModalBrand,"onBrandChange":_vm.onBrandChange},on:{"setShowModalBrand":_vm.setShowModalBrand}}),_c('ModalImport',{attrs:{"isShow":_vm.isShowModalImport,"uploadTracking":_vm.uploadTracking,"shippingLists":_vm.shippingLists},on:{"setShowModalImport":_vm.setShowModalImport}}),_c('ModalExportJobsheet',{attrs:{"isShow":_vm.isShowModalExport},on:{"setShowModalExport":_vm.setShowModalExport}}),_c('b-modal',{attrs:{"id":"modal-1","hide-footer":"","hide-header":""},model:{value:(_vm.result),callback:function ($$v) {_vm.result=$$v},expression:"result"}},[_c('div',{staticClass:"m-3"},[_c('div',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.resultMessage))]),_vm._l((_vm.errorLists),function(item,index){return _c('small',{key:index + 'error',staticClass:"text-danger"},[_vm._v(" "+_vm._s(item)+" ")])})],2)])],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }